.ContactForm {

}
/* on desktop, the form is 50% of the screen width */
@media (min-width: 800px) {
  .ContactForm {
    width: 60vw;
  }
}
/* on mobile, the form is 90% of the screen width */
@media (max-width: 799px) {
  .ContactForm {
    width: 95vw;
  }
}